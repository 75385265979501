
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index3qwjgP7BrpgJKK6yKm9qDP9LpFEU8KnZ0trLUFpcliwMeta } from "/app/packages/frontend/src/pages/index.vue?macro=true";
import { default as backupsaq_UyCELCDjMdOUfN8jWISfV2Xstf_26xKx7s1uPKNUMeta } from "/app/packages/frontend/src/pages/backups.vue?macro=true";
import { default as license5TxVm3P3lgYLFTNdWJ3w7afj_5xzJB6e22WFZEG1G4QMeta } from "/app/packages/frontend/src/pages/license.vue?macro=true";
import { default as _91_46_46_46path_93_45V6EkXlB3yo4o74Aa3Vae1G6nCirNHEaIc4qMgWSVxUMeta } from "/app/packages/frontend/src/pages/plugins/[...path].vue?macro=true";
import { default as pluginsd4sVftK5r6P5pZ0HbRan_45UWd4hOsl1SIliRkgB1nd8sMeta } from "/app/packages/frontend/src/pages/plugins.vue?macro=true";
import { default as settings4yedP0vDZuBftfIf_ZIbUTU56DTg4oiGHXx72bbouScMeta } from "/app/packages/frontend/src/pages/settings.vue?macro=true";
import { default as newiZTKFKyWZSc_oeQ2TRiwoM09clDTIdw5OjqpN7uqGAsMeta } from "/app/packages/frontend/src/pages/users/new.vue?macro=true";
import { default as autoLiAobKyMZgR_v97Z1DCK02XuDNzCK3ERFM_45hhnpsjEUMeta } from "/app/packages/frontend/src/pages/login/auto.vue?macro=true";
import { default as indexMOlZUft_nfWkJg2pCnz15vA_45hmIXzRaq083EiD8AsMUMeta } from "/app/packages/frontend/src/pages/users/self/index.vue?macro=true";
import { default as securityOG3S8Z0fSPBHTtbq5gKa2dI0YQQNhGqPE8K7LXrYRikMeta } from "/app/packages/frontend/src/pages/users/self/security.vue?macro=true";
import { default as apitokensyYy_45lYhGWewiZ1Ugvq0HbFqF7szrKxJ1QZdCoHqD_xkMeta } from "/app/packages/frontend/src/pages/users/self/apitokens.vue?macro=true";
import { default as publickeys0IjBlJJIor3PS_45oLNdvEG1XTzz8xUBeJYEGdGK1iFAgMeta } from "/app/packages/frontend/src/pages/users/self/publickeys.vue?macro=true";
import { default as enablevlAxuqLeo9c0SnqEfonirNlU2_2uOdaJuZxqPKwSNVoMeta } from "/app/packages/frontend/src/pages/users/self/admin/enable.vue?macro=true";
import { default as disableMRAEvgL3JiaEHf0aLUgasitfK68_45vdeFmEUfOJ6SIW4Meta } from "/app/packages/frontend/src/pages/users/self/admin/disable.vue?macro=true";
import { default as selfNWBdNvSfmBtV6peSl0OnlwNgfuJSfCiOrFOaRqNas4EMeta } from "/app/packages/frontend/src/pages/users/self.vue?macro=true";
import { default as indexpTd4ugj9eDyjgCKI6mH1fdkDslwZZ7nJD5qbQ4_22W8Meta } from "/app/packages/frontend/src/pages/login/index.vue?macro=true";
import { default as localXa4sMyPoOjdnLvF6oHbCW0yCP7m6BvELTUgtZypuwDwMeta } from "/app/packages/frontend/src/pages/login/local.vue?macro=true";
import { default as indexwWJIEeHdQn_edgr2F_8tCXaihRydVUA7Ss6_neKuYWsMeta } from "/app/packages/frontend/src/pages/users/index.vue?macro=true";
import { default as reauthcpjrZ9MeLj9uJJ5AfI6I58Khi6cfaro8XH4IH_bj2mgMeta } from "/app/packages/frontend/src/pages/login/reauth.vue?macro=true";
import { default as newzwY2ggu7N1PmEYL8uVkKQZ0Mf6Fyo_45cw1HVHaBR1qMYMeta } from "/app/packages/frontend/src/pages/projects/new.vue?macro=true";
import { default as indexzrr4KxmcPgrWk8uuubdw_ri9oD9t7cMUmMtyW4Ix4HkMeta } from "/app/packages/frontend/src/pages/designs/index.vue?macro=true";
import { default as indexY9kC5g_1Uz9_45HNwsjsZty_45b1E6xWlO3mcbPJS2w6jc8Meta } from "/app/packages/frontend/src/pages/notes/personal/index.vue?macro=true";
import { default as _91noteId_93LJhs5W5xzAUfPaRPlC2bM6dVJmTNaaHvsue8slEG4_45EMeta } from "/app/packages/frontend/src/pages/notes/personal/[noteId].vue?macro=true";
import { default as personaldPFIL7gfnDZWyEc30c_CGurMLGFFRBmTUKLJfAXFPggMeta } from "/app/packages/frontend/src/pages/notes/personal.vue?macro=true";
import { default as indexivWxvKHvSly5FmbZQpT_45GjD37IzHMZ0fDvLLWyhULVkMeta } from "/app/packages/frontend/src/pages/projects/index.vue?macro=true";
import { default as mfauOpIePghM8b80fRJ2rm4OErlK6acCKaVXBhTqSENocsMeta } from "/app/packages/frontend/src/pages/users/[userId]/mfa.vue?macro=true";
import { default as index9aR0oEt7ObILf_45q_45OPIXZP8WqXMCGZnzakCRN9_45Uj8IMeta } from "/app/packages/frontend/src/pages/users/[userId]/index.vue?macro=true";
import { default as apitokenswFAwHdt9u0JqMJMJZWkWuAn8I2L5a7mIfc0TUa05FegMeta } from "/app/packages/frontend/src/pages/users/[userId]/apitokens.vue?macro=true";
import { default as identitiesM2o_bQIQHxTQCybhvPAh2_S18CLMjB8iJcgJh3C4Jn4Meta } from "/app/packages/frontend/src/pages/users/[userId]/identities.vue?macro=true";
import { default as reset_45passwordNkzaJXhZP3Y_LCC_H3nK_1DzVj6iCfuCxNN6FQVK4cgMeta } from "/app/packages/frontend/src/pages/users/[userId]/reset-password.vue?macro=true";
import { default as _91userId_935lN62vNfhnxa7csqGr7Y7LPEwv2D_45oNETusLeirxPaMMeta } from "/app/packages/frontend/src/pages/users/[userId].vue?macro=true";
import { default as privateqJQNXI8a9YqxbQ9kHCoXz0r030zMV_3U_pjcC6GuFxsMeta } from "/app/packages/frontend/src/pages/designs/private.vue?macro=true";
import { default as indexrS536EDnkZof_ttYoOrVM3287VG0MkthX6g_tIXbAysMeta } from "/app/packages/frontend/src/pages/templates/index.vue?macro=true";
import { default as finished0n2P0f5CSSoEFHLJ0ZJt2sbhyN4fYWliQ4_45AcAfoaygMeta } from "/app/packages/frontend/src/pages/projects/finished.vue?macro=true";
import { default as indexBtjf85Qm8TFnB5J9ZPAqxtam14u_45Vh6VgKp_r201_45oUMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/index.vue?macro=true";
import { default as index8uEWsrKFMp12WVGbUThwwHxoVMzs1gXctgM9na3C2hsMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/notes/index.vue?macro=true";
import { default as indexdFTm8LIByhxVVYTVZpl2FeY3MsVVlTjOpJ3KT4jxH_8Meta } from "/app/packages/frontend/src/pages/projects/[projectId]/notes/[noteId]/index.vue?macro=true";
import { default as _91historyDate_93_45E4wRqWhndwqzGNalVzbXD3WcM4o0zuQv_ySxZaZCf4Meta } from "/app/packages/frontend/src/pages/projects/[projectId]/notes/[noteId]/history/[historyDate].vue?macro=true";
import { default as notesJlThyjFa_2gni_nIikX0muSldQlrKdybUIuJDaX5NgMMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/notes.vue?macro=true";
import { default as archiveL4nWPIcR9kMtWy7I8_pucu7gNhK_45lllNrQv4NXsWO6gMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/archive.vue?macro=true";
import { default as historyync16BRA3TMF_iVy2CH_YL1xNyJqrZdbmL_KyXQXWFsMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/history.vue?macro=true";
import { default as _91_46_46_46path_93zuN49JanYKxrZ3DAd0_45eXlUT__45_evjAjC97E6rreIV4Meta } from "/app/packages/frontend/src/pages/projects/[projectId]/plugins/[...path].vue?macro=true";
import { default as plugins41WUXu6dnHnxYj6BId8UeeoYNKA4ia4_45OXlsluRDY4QMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/plugins.vue?macro=true";
import { default as publishrDtYs9cxDYwtoVBM4h6DxTFah2nDLouZokkYS81sImIMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/publish.vue?macro=true";
import { default as designerSgBFkL0VfH3wCUAWyKhIGKZCxEYT_ez_nvWpAcIIjMcMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/designer.vue?macro=true";
import { default as indexMpY8YW24Db_Ay8IM4DStIMbvdqvkapCicNo8B_45WwiAoMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting/index.vue?macro=true";
import { default as indexIQmdom82UtbtuxjGcVT_q4yclif2fvIJOJkGs6ESibMMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/index.vue?macro=true";
import { default as indexAzM7Jvf9dm42bYsNatO_45rN8hS8LOxgvC_5t_45niQVUxoMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/index.vue?macro=true";
import { default as _91historyDate_931Ga7__gzUaRKRUhi9dw5oS5ZsP4QMVL5MK173fBuodYMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/history/[historyDate].vue?macro=true";
import { default as _91historyDate_93nvjgxGv7sgGe_IVqgW6aInozii0ava0ICZqQzjn9syYMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/history/[historyDate].vue?macro=true";
import { default as reportinggIZWmA5E_45hVvWoIWyA8QpSl4vAOshJTiPrf3PiVDpbcMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting.vue?macro=true";
import { default as _91projectId_931G8Sf_45PwJjv9rbHquJznyG2zSrkaVJykxg9U0YXBLcYMeta } from "/app/packages/frontend/src/pages/projects/[projectId].vue?macro=true";
import { default as fromfindingjOXdsJFYf9kWWeu1kSn_Liy0zRGnHafdSrn4QMG01foMeta } from "/app/packages/frontend/src/pages/templates/fromfinding.vue?macro=true";
import { default as indexO9fkwCs5W2wDUvPQk7Qx81rpSvCSBvMjfPsrmLqnDPQMeta } from "/app/packages/frontend/src/pages/designs/[projectTypeId]/index.vue?macro=true";
import { default as notesVZ4Qek5DYFfjJ82EJh_UkQn16qjk9O88ThGiFJ2YAlcMeta } from "/app/packages/frontend/src/pages/designs/[projectTypeId]/notes.vue?macro=true";
import { default as pdfdesignerEheB0mDBKE6XfWT750WgBzAfLGNPTx8z3LJIO86MVckMeta } from "/app/packages/frontend/src/pages/designs/[projectTypeId]/pdfdesigner.vue?macro=true";
import { default as reportfieldsaG8iOsF9AXy1tgU4Ged2dR1IZzyLWfxnyWynx9NrFZMMeta } from "/app/packages/frontend/src/pages/designs/[projectTypeId]/reportfields.vue?macro=true";
import { default as findingfieldsp51W3PQ3Ohev6Bnnsz7ynePUcNwLwxP795bF5tvnL7MMeta } from "/app/packages/frontend/src/pages/designs/[projectTypeId]/findingfields.vue?macro=true";
import { default as _91projectTypeId_934lt3mx_gg69GlDd3W9R7Z_kpqZB_45tYFSZ4Gy_SPZenwMeta } from "/app/packages/frontend/src/pages/designs/[projectTypeId].vue?macro=true";
import { default as indexkxRkGUPtwrEpD_sK7ulbzUVSbBqHUEAUtYttD98kNZEMeta } from "/app/packages/frontend/src/pages/projects/archived/index.vue?macro=true";
import { default as indextHmFB_45xXvgf_LDvG9fyLTgn80rf_qovz3aNYAeLnpEwMeta } from "/app/packages/frontend/src/pages/shared/[shareInfoId]/index.vue?macro=true";
import { default as indexhI1ZVOmB6wtNnndPcHCCPVp09qGxPCMRIDunWVbd7vMMeta } from "/app/packages/frontend/src/pages/shared/[shareInfoId]/notes/index.vue?macro=true";
import { default as _91noteId_93sLkp_fTgwDLYiOHb8sOysjRgHwJfUJERCY_34NGgVnIMeta } from "/app/packages/frontend/src/pages/shared/[shareInfoId]/notes/[noteId].vue?macro=true";
import { default as notesOHi3eChz1WYKjAxYMkhCqKzCW_45Fp_jVlG_vmCPBsQcsMeta } from "/app/packages/frontend/src/pages/shared/[shareInfoId]/notes.vue?macro=true";
import { default as indexIa4CbwgDzGfsijnkvg5_UTlKHWB7eLpTMUQk9gRHA4AMeta } from "/app/packages/frontend/src/pages/templates/[templateId]/index.vue?macro=true";
import { default as _91archiveId_93PNEJ_m8fDlVHYAgR6e1M44jzvqQpFM0uZTqUw0Gfsr0Meta } from "/app/packages/frontend/src/pages/projects/archived/[archiveId].vue?macro=true";
import { default as callbackr_45qwhhCZRZzdvdd5jumwg1l05j3znA2nNqdtRhiddu0Meta } from "/app/packages/frontend/src/pages/login/oidc/[authProviderId]/callback.vue?macro=true";
import { default as _91historyDate_93kXB8muZWrOC9jhsilgOluS_45fzPoYq4NY_451mMgbOc3pwMeta } from "/app/packages/frontend/src/pages/templates/[templateId]/history/[historyDate].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: index3qwjgP7BrpgJKK6yKm9qDP9LpFEU8KnZ0trLUFpcliwMeta || {},
    component: () => import("/app/packages/frontend/src/pages/index.vue")
  },
  {
    name: "backups",
    path: "/backups",
    meta: backupsaq_UyCELCDjMdOUfN8jWISfV2Xstf_26xKx7s1uPKNUMeta || {},
    component: () => import("/app/packages/frontend/src/pages/backups.vue")
  },
  {
    name: "license",
    path: "/license",
    meta: license5TxVm3P3lgYLFTNdWJ3w7afj_5xzJB6e22WFZEG1G4QMeta || {},
    component: () => import("/app/packages/frontend/src/pages/license.vue")
  },
  {
    name: "plugins-main",
    path: "/plugins",
    meta: pluginsd4sVftK5r6P5pZ0HbRan_45UWd4hOsl1SIliRkgB1nd8sMeta || {},
    component: () => import("/app/packages/frontend/src/pages/plugins.vue"),
    children: [
  {
    name: "plugins-path",
    path: ":path(.*)*",
    component: () => import("/app/packages/frontend/src/pages/plugins/[...path].vue")
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settings4yedP0vDZuBftfIf_ZIbUTU56DTg4oiGHXx72bbouScMeta || {},
    component: () => import("/app/packages/frontend/src/pages/settings.vue")
  },
  {
    name: "users-new",
    path: "/users/new",
    component: () => import("/app/packages/frontend/src/pages/users/new.vue")
  },
  {
    name: "login-auto",
    path: "/login/auto",
    meta: autoLiAobKyMZgR_v97Z1DCK02XuDNzCK3ERFM_45hhnpsjEUMeta || {},
    component: () => import("/app/packages/frontend/src/pages/login/auto.vue")
  },
  {
    name: selfNWBdNvSfmBtV6peSl0OnlwNgfuJSfCiOrFOaRqNas4EMeta?.name,
    path: "/users/self",
    component: () => import("/app/packages/frontend/src/pages/users/self.vue"),
    children: [
  {
    name: "users-self",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/users/self/index.vue")
  },
  {
    name: "users-self-security",
    path: "security",
    component: () => import("/app/packages/frontend/src/pages/users/self/security.vue")
  },
  {
    name: "users-self-apitokens",
    path: "apitokens",
    component: () => import("/app/packages/frontend/src/pages/users/self/apitokens.vue")
  },
  {
    name: "users-self-publickeys",
    path: "publickeys",
    component: () => import("/app/packages/frontend/src/pages/users/self/publickeys.vue")
  },
  {
    name: "users-self-admin-enable",
    path: "admin/enable",
    component: () => import("/app/packages/frontend/src/pages/users/self/admin/enable.vue")
  },
  {
    name: "users-self-admin-disable",
    path: "admin/disable",
    component: () => import("/app/packages/frontend/src/pages/users/self/admin/disable.vue")
  }
]
  },
  {
    name: "login",
    path: "/login",
    meta: indexpTd4ugj9eDyjgCKI6mH1fdkDslwZZ7nJD5qbQ4_22W8Meta || {},
    component: () => import("/app/packages/frontend/src/pages/login/index.vue")
  },
  {
    name: "login-local",
    path: "/login/local",
    meta: localXa4sMyPoOjdnLvF6oHbCW0yCP7m6BvELTUgtZypuwDwMeta || {},
    component: () => import("/app/packages/frontend/src/pages/login/local.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexwWJIEeHdQn_edgr2F_8tCXaihRydVUA7Ss6_neKuYWsMeta || {},
    component: () => import("/app/packages/frontend/src/pages/users/index.vue")
  },
  {
    name: "login-reauth",
    path: "/login/reauth",
    meta: reauthcpjrZ9MeLj9uJJ5AfI6I58Khi6cfaro8XH4IH_bj2mgMeta || {},
    component: () => import("/app/packages/frontend/src/pages/login/reauth.vue")
  },
  {
    name: "projects-new",
    path: "/projects/new",
    component: () => import("/app/packages/frontend/src/pages/projects/new.vue")
  },
  {
    name: "designs",
    path: "/designs",
    meta: indexzrr4KxmcPgrWk8uuubdw_ri9oD9t7cMUmMtyW4Ix4HkMeta || {},
    component: () => import("/app/packages/frontend/src/pages/designs/index.vue")
  },
  {
    name: personaldPFIL7gfnDZWyEc30c_CGurMLGFFRBmTUKLJfAXFPggMeta?.name,
    path: "/notes/personal",
    component: () => import("/app/packages/frontend/src/pages/notes/personal.vue"),
    children: [
  {
    name: "notes-personal",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/notes/personal/index.vue")
  },
  {
    name: "notes-personal-noteId",
    path: ":noteId()",
    component: () => import("/app/packages/frontend/src/pages/notes/personal/[noteId].vue")
  }
]
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexivWxvKHvSly5FmbZQpT_45GjD37IzHMZ0fDvLLWyhULVkMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/index.vue")
  },
  {
    name: _91userId_935lN62vNfhnxa7csqGr7Y7LPEwv2D_45oNETusLeirxPaMMeta?.name,
    path: "/users/:userId()",
    component: () => import("/app/packages/frontend/src/pages/users/[userId].vue"),
    children: [
  {
    name: "users-userId-mfa",
    path: "mfa",
    component: () => import("/app/packages/frontend/src/pages/users/[userId]/mfa.vue")
  },
  {
    name: "users-userId",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/users/[userId]/index.vue")
  },
  {
    name: "users-userId-apitokens",
    path: "apitokens",
    component: () => import("/app/packages/frontend/src/pages/users/[userId]/apitokens.vue")
  },
  {
    name: "users-userId-identities",
    path: "identities",
    component: () => import("/app/packages/frontend/src/pages/users/[userId]/identities.vue")
  },
  {
    name: "users-userId-reset-password",
    path: "reset-password",
    component: () => import("/app/packages/frontend/src/pages/users/[userId]/reset-password.vue")
  }
]
  },
  {
    name: "designs-private",
    path: "/designs/private",
    meta: privateqJQNXI8a9YqxbQ9kHCoXz0r030zMV_3U_pjcC6GuFxsMeta || {},
    component: () => import("/app/packages/frontend/src/pages/designs/private.vue")
  },
  {
    name: "templates",
    path: "/templates",
    meta: indexrS536EDnkZof_ttYoOrVM3287VG0MkthX6g_tIXbAysMeta || {},
    component: () => import("/app/packages/frontend/src/pages/templates/index.vue")
  },
  {
    name: "projects-finished",
    path: "/projects/finished",
    meta: finished0n2P0f5CSSoEFHLJ0ZJt2sbhyN4fYWliQ4_45AcAfoaygMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/finished.vue")
  },
  {
    name: _91projectId_931G8Sf_45PwJjv9rbHquJznyG2zSrkaVJykxg9U0YXBLcYMeta?.name,
    path: "/projects/:projectId()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId].vue"),
    children: [
  {
    name: "projects-projectId",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/index.vue")
  },
  {
    name: notesJlThyjFa_2gni_nIikX0muSldQlrKdybUIuJDaX5NgMMeta?.name,
    path: "notes",
    meta: notesJlThyjFa_2gni_nIikX0muSldQlrKdybUIuJDaX5NgMMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/notes.vue"),
    children: [
  {
    name: "projects-projectId-notes",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/notes/index.vue")
  },
  {
    name: "projects-projectId-notes-noteId",
    path: ":noteId()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/notes/[noteId]/index.vue")
  },
  {
    name: "projects-projectId-notes-noteId-history-historyDate",
    path: ":noteId()/history/:historyDate()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/notes/[noteId]/history/[historyDate].vue")
  }
]
  },
  {
    name: "projects-projectId-archive",
    path: "archive",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/archive.vue")
  },
  {
    name: "projects-projectId-history",
    path: "history",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/history.vue")
  },
  {
    name: "plugins-project",
    path: "plugins",
    meta: plugins41WUXu6dnHnxYj6BId8UeeoYNKA4ia4_45OXlsluRDY4QMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/plugins.vue"),
    children: [
  {
    name: "projects-projectId-plugins-path",
    path: ":path(.*)*",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/plugins/[...path].vue")
  }
]
  },
  {
    name: "projects-projectId-publish",
    path: "publish",
    meta: publishrDtYs9cxDYwtoVBM4h6DxTFah2nDLouZokkYS81sImIMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/publish.vue")
  },
  {
    name: "projects-projectId-designer",
    path: "designer",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/designer.vue")
  },
  {
    name: reportinggIZWmA5E_45hVvWoIWyA8QpSl4vAOshJTiPrf3PiVDpbcMeta?.name,
    path: "reporting",
    meta: reportinggIZWmA5E_45hVvWoIWyA8QpSl4vAOshJTiPrf3PiVDpbcMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting.vue"),
    children: [
  {
    name: "projects-projectId-reporting",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting/index.vue")
  },
  {
    name: "projects-projectId-reporting-findings-findingId",
    path: "findings/:findingId()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/index.vue")
  },
  {
    name: "projects-projectId-reporting-sections-sectionId",
    path: "sections/:sectionId()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/index.vue")
  },
  {
    name: "projects-projectId-reporting-findings-findingId-history-historyDate",
    path: "findings/:findingId()/history/:historyDate()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/history/[historyDate].vue")
  },
  {
    name: "projects-projectId-reporting-sections-sectionId-history-historyDate",
    path: "sections/:sectionId()/history/:historyDate()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/history/[historyDate].vue")
  }
]
  }
]
  },
  {
    name: "templates-fromfinding",
    path: "/templates/fromfinding",
    component: () => import("/app/packages/frontend/src/pages/templates/fromfinding.vue")
  },
  {
    name: _91projectTypeId_934lt3mx_gg69GlDd3W9R7Z_kpqZB_45tYFSZ4Gy_SPZenwMeta?.name,
    path: "/designs/:projectTypeId()",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId].vue"),
    children: [
  {
    name: "designs-projectTypeId",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId]/index.vue")
  },
  {
    name: "designs-projectTypeId-notes",
    path: "notes",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId]/notes.vue")
  },
  {
    name: "designs-projectTypeId-pdfdesigner",
    path: "pdfdesigner",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId]/pdfdesigner.vue")
  },
  {
    name: "designs-projectTypeId-reportfields",
    path: "reportfields",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId]/reportfields.vue")
  },
  {
    name: "designs-projectTypeId-findingfields",
    path: "findingfields",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId]/findingfields.vue")
  }
]
  },
  {
    name: "projects-archived",
    path: "/projects/archived",
    meta: indexkxRkGUPtwrEpD_sK7ulbzUVSbBqHUEAUtYttD98kNZEMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/archived/index.vue")
  },
  {
    name: "shared-shareInfoId",
    path: "/shared/:shareInfoId()",
    meta: indextHmFB_45xXvgf_LDvG9fyLTgn80rf_qovz3aNYAeLnpEwMeta || {},
    component: () => import("/app/packages/frontend/src/pages/shared/[shareInfoId]/index.vue")
  },
  {
    name: notesOHi3eChz1WYKjAxYMkhCqKzCW_45Fp_jVlG_vmCPBsQcsMeta?.name,
    path: "/shared/:shareInfoId()/notes",
    meta: notesOHi3eChz1WYKjAxYMkhCqKzCW_45Fp_jVlG_vmCPBsQcsMeta || {},
    component: () => import("/app/packages/frontend/src/pages/shared/[shareInfoId]/notes.vue"),
    children: [
  {
    name: "shared-shareInfoId-notes",
    path: "",
    meta: indexhI1ZVOmB6wtNnndPcHCCPVp09qGxPCMRIDunWVbd7vMMeta || {},
    component: () => import("/app/packages/frontend/src/pages/shared/[shareInfoId]/notes/index.vue")
  },
  {
    name: "shared-shareInfoId-notes-noteId",
    path: ":noteId()",
    meta: _91noteId_93sLkp_fTgwDLYiOHb8sOysjRgHwJfUJERCY_34NGgVnIMeta || {},
    component: () => import("/app/packages/frontend/src/pages/shared/[shareInfoId]/notes/[noteId].vue")
  }
]
  },
  {
    name: "templates-templateId",
    path: "/templates/:templateId()",
    component: () => import("/app/packages/frontend/src/pages/templates/[templateId]/index.vue")
  },
  {
    name: "projects-archived-archiveId",
    path: "/projects/archived/:archiveId()",
    component: () => import("/app/packages/frontend/src/pages/projects/archived/[archiveId].vue")
  },
  {
    name: "login-oidc-authProviderId-callback",
    path: "/login/oidc/:authProviderId()/callback",
    meta: callbackr_45qwhhCZRZzdvdd5jumwg1l05j3znA2nNqdtRhiddu0Meta || {},
    component: () => import("/app/packages/frontend/src/pages/login/oidc/[authProviderId]/callback.vue")
  },
  {
    name: "templates-templateId-history-historyDate",
    path: "/templates/:templateId()/history/:historyDate()",
    component: () => import("/app/packages/frontend/src/pages/templates/[templateId]/history/[historyDate].vue")
  }
]