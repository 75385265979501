import payload_plugin_BNPKVw66obyvXK_hHapqTHFTsNu_iOJBct_9HiKmR5k from "/app/packages/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0 from "/app/packages/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_cMJp2JJAYTWyh08NEJWZWZYOAYmJBWzPNc_30nup5Po from "/app/packages/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ from "/app/packages/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI from "/app/packages/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0 from "/app/packages/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kRdP0hsyNSWRJESGlaqVgRQ1Bgm0NpNaCfqmEeSNd0I from "/app/packages/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk from "/app/packages/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0 from "/app/packages/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/app/packages/frontend/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8 from "/app/packages/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_X6uoSbd14yYjfZqac5xN0eVfgR5WXOEpHPNmPiNRn9o from "/app/packages/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import vuetify_no_client_hints_udLlyg3Qp1u95om6h64pzFe8fpQSmBpsGSLRBoEkWJI from "/app/packages/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import toast_iiDfEm96Zfx0ow7lZ09WqBnuY5VR1xcaV9EpneDp0po from "/app/packages/nuxt-base-layer/src/plugins/toast.ts";
import auth_KUdagv3oyvSBS3mO749eP0dD8u9oBnRf_V2qaChjLVc from "/app/packages/frontend/src/plugins/auth.ts";
import settings_TLfGWdiSNJc_Jal3z__QqQd76a2BQ_qeHqan91JA8GU from "/app/packages/frontend/src/plugins/settings.ts";
import trustedtypes_Obfqn8l0vkY_cMRQQ_ayvU97b9zP1E4kSiNXEb12LqY from "/app/packages/frontend/src/plugins/trustedtypes.ts";
import pluginLoader_p3o9BrxNaI0ftRBtA_UNoFn8xeBX_E57C1phedMO6YA from "/app/packages/frontend/src/plugins/pluginLoader.ts";
import vuetify_nuxt_plugin_client_vhPL_4E3qi2WkYEd3Y5XADFMLa2GBXOaOwhkLQcelWw from "/app/packages/frontend/node_modules/.cache/nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_BNPKVw66obyvXK_hHapqTHFTsNu_iOJBct_9HiKmR5k,
  revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0,
  vuetify_icons_cMJp2JJAYTWyh08NEJWZWZYOAYmJBWzPNc_30nup5Po,
  unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ,
  router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI,
  navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0,
  check_outdated_build_client_kRdP0hsyNSWRJESGlaqVgRQ1Bgm0NpNaCfqmEeSNd0I,
  chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk,
  plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8,
  plugin_X6uoSbd14yYjfZqac5xN0eVfgR5WXOEpHPNmPiNRn9o,
  vuetify_no_client_hints_udLlyg3Qp1u95om6h64pzFe8fpQSmBpsGSLRBoEkWJI,
  toast_iiDfEm96Zfx0ow7lZ09WqBnuY5VR1xcaV9EpneDp0po,
  auth_KUdagv3oyvSBS3mO749eP0dD8u9oBnRf_V2qaChjLVc,
  settings_TLfGWdiSNJc_Jal3z__QqQd76a2BQ_qeHqan91JA8GU,
  trustedtypes_Obfqn8l0vkY_cMRQQ_ayvU97b9zP1E4kSiNXEb12LqY,
  pluginLoader_p3o9BrxNaI0ftRBtA_UNoFn8xeBX_E57C1phedMO6YA,
  vuetify_nuxt_plugin_client_vhPL_4E3qi2WkYEd3Y5XADFMLa2GBXOaOwhkLQcelWw
]